import { useContext, useEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Form } from 'react-bootstrap';
import cx from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import {
  StyledCard,
  StyledCardBody,
  StyledHeader,
  StyledIcon,
} from '../../../ui/Styled';
import BasicSpinner from '../../../ui/BasicSpinner';
import ErrorLabel from '../../../ui/ErrorLabel';
import batterySvg from '../../../assets/svg/battery.svg';
import { OnboardingContext } from '../../../context/OnboardingContext';
import { getSupportedHardware, HardwareTypes } from '../../../api/Optimise';
import { getUnitList } from '../../../utils/dataUtils';
import { fetchContentfulTag } from '../../../utils/fetchContentfulTag';

const SpecialButton = styled(Button)`
  border-radius: 4px;
  text-align: left;
  padding: 22px 15px;
  margin-top: 24px;
`;

const BatteryDetails = ({ values }) => {
  const [maxBatteries, setMaxBatteries] = useState(null);
  const [maxBatteryTypes, setMaxBatteryTypes] = useState(null);

  const { batteryUnitList, setBatteryUnitList, setBatteries } =
    useContext(OnboardingContext);

  const [batteryConfigList, setBatteryConfigList] = useState(null);
  const [loading, setLoading] = useState(true);

  const selectBatteryManufacturer = async (index, manufacturer) => {
    const unitList = batteryUnitList;
    unitList[index] = getUnitList(
      batteryConfigList?.manufacturer_list,
      manufacturer
    );
    setBatteryUnitList(unitList);
  };

  const removeBatteryDetails = (index, arrayHelpers) => {
    arrayHelpers.remove(index);
    const newBatteryUnitList = batteryUnitList.filter((_, i) => i !== index);
    setBatteryUnitList(newBatteryUnitList);
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      const supportedBatteries = await getSupportedHardware(
        HardwareTypes.BATTERY
      );
      setBatteryConfigList(supportedBatteries);
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    setBatteries(values.batteries);
  }, [values.batteries, setBatteries]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (batteryConfigList) {
        if (values.batteries) {
          for (const [index, battery] of values.batteries.entries()) {
            if (battery.manufacturer) {
              await selectBatteryManufacturer(index, battery.manufacturer);
            }
          }
        }
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [batteryConfigList]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const data = await fetchContentfulTag(
        'config_max_batteries',
        'keyValuePair'
      );
      setMaxBatteries(parseInt(data?.value));
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const data = await fetchContentfulTag(
        'config_max_battery_types',
        'keyValuePair'
      );
      setMaxBatteryTypes(parseInt(data?.value));
    };
    asyncUseEffect();
  }, []);

  return (
    <div className="mb-3">
      <FieldArray
        name="batteries"
        render={arrayHelpers => (
          <>
            {values.batteries?.map((battery, index) => (
              <div key={index}>
                <StyledCard>
                  <StyledHeader>
                    <h4>
                      Battery Model {values.batteries?.length > 1 && index + 1}
                    </h4>
                    <StyledIcon icon="battery">
                      <img src={batterySvg} alt="Battery" />
                    </StyledIcon>
                  </StyledHeader>
                  <div>
                    <StyledCardBody>
                      <Field name={`batteries.${index}.manufacturer`}>
                        {({ field, meta }) => (
                          <Form.Group
                            controlId={`batteries.${index}.manufacturer`}>
                            <Form.Label>
                              Select battery manufacturer
                              {!batteryConfigList && <BasicSpinner />}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              size="md"
                              value={field.value}
                              onChange={e => {
                                selectBatteryManufacturer(
                                  index,
                                  e.target.value
                                );
                                return field.onChange(e);
                              }}
                              onBlur={field.onBlur}
                              isValid={!meta.error && meta.touched}
                              placeholder="Select battery manufacturer?"
                              className={cx({ error: !!meta.error })}
                              disabled={!batteryConfigList}>
                              <optgroup>
                                <option value="" key="manufacturer-select">
                                  Select battery manufacturer
                                </option>
                                {batteryConfigList?.manufacturer_list?.map(
                                  ({ manufacturer }, index) => (
                                    <option
                                      key={`manufacturer-${index}`}
                                      value={manufacturer}>
                                      {manufacturer}
                                    </option>
                                  )
                                )}
                              </optgroup>
                            </Form.Control>
                            {meta.error && meta.touched && (
                              <ErrorLabel label={meta.error} />
                            )}
                          </Form.Group>
                        )}
                      </Field>
                      {index === 0 && (
                        <Form.Group className="not-found-text">
                          <div>
                            Manufacturer not shown?
                            <Button
                              variant="link"
                              href="https://share.hsforms.com/1ads6NpkKSwWY7IjuyzWgIQ2uro2"
                              target="_blank"
                              className="p-0 not-found-text ml-1">
                              Let us know
                            </Button>
                          </div>
                        </Form.Group>
                      )}
                      <Field name={`batteries.${index}.model`}>
                        {({ field, meta }) => (
                          <Form.Group
                            controlId={`batteries.${index}.model`}
                            className={cx({ 'mt-4': index !== 0 })}>
                            <Form.Label>Select battery model</Form.Label>
                            {loading ? (
                              <div className="mt-2 mb-2">
                                <BasicSpinner />
                              </div>
                            ) : (
                              <Form.Control
                                as="select"
                                size="md"
                                value={field.value}
                                onChange={e => {
                                  field.onChange(e);
                                }}
                                onBlur={field.onBlur}
                                isValid={!meta.error && meta.touched}
                                placeholder="Select battery model"
                                disabled={!batteryUnitList?.length}
                                className={cx({ error: !!meta.error })}>
                                <optgroup>
                                  <option value="" key="battery-select">
                                    Select battery model
                                  </option>
                                  {batteryUnitList?.[index]?.map(
                                    ({ unit_name }, index) => (
                                      <option
                                        key={`model-${index}`}
                                        value={unit_name}>
                                        {unit_name}
                                      </option>
                                    )
                                  )}
                                </optgroup>
                              </Form.Control>
                            )}
                            {meta.error && meta.touched && (
                              <ErrorLabel label={meta.error} />
                            )}
                          </Form.Group>
                        )}
                      </Field>

                      <Field name={`batteries.${index}.quantity`}>
                        {({ field, meta }) => (
                          <Form.Group
                            controlId={`batteries.${index}.quantity`}
                            className="mt-4 mb-4">
                            <Form.Label>
                              How many of these batteries do you have?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              size="md"
                              value={field.value}
                              onChange={e => {
                                field.onChange(e);
                              }}
                              onBlur={field.onBlur}
                              isValid={!meta.error && meta.touched}
                              placeholder="Select quantity"
                              disabled={!maxBatteries}
                              className={cx({ error: !!meta.error })}>
                              <optgroup>
                                <option value="" key="quantity-select">
                                  Select quantity
                                </option>
                                {maxBatteries !== null &&
                                  [...Array(maxBatteries).keys()].map(i => (
                                    <option key={i + 1} value={i + 1}>
                                      {i + 1}
                                    </option>
                                  ))}
                              </optgroup>
                            </Form.Control>
                            {meta.error && meta.touched && (
                              <ErrorLabel label={meta.error} />
                            )}
                          </Form.Group>
                        )}
                      </Field>
                      {values.batteries?.length > 1 && (
                        <Button
                          onClick={() =>
                            removeBatteryDetails(index, arrayHelpers)
                          }
                          variant="secondary"
                          block>
                          <FontAwesomeIcon icon={faCircleMinus} />
                          &nbsp; Remove battery model {index + 1}
                        </Button>
                      )}
                    </StyledCardBody>
                  </div>
                </StyledCard>
              </div>
            ))}
            {!!maxBatteryTypes &&
              values.batteries?.length < maxBatteryTypes && (
                <SpecialButton
                  className="mb-0"
                  variant="secondary"
                  onClick={() =>
                    arrayHelpers.push({
                      manufacturer: null,
                      model: null,
                      quantity: null,
                    })
                  }
                  block>
                  <FontAwesomeIcon icon={faCirclePlus} className="mr-1" />
                  &nbsp;Add a different battery
                </SpecialButton>
              )}
          </>
        )}
      />
    </div>
  );
};

export default BatteryDetails;
