import styled from 'styled-components';

const StyledIcon = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.icon === 'inverter'
      ? 'linear-gradient(to right, #e5098b, #ff594f)'
      : 'linear-gradient(to right, #2747b1, #6433a5)'};

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
  }

  img {
    width: 20px;
    height: 20px;

    @media (min-width: 576px) {
      width: 30px;
      height: 30px;
    }
  }
`;

export default StyledIcon;
