export const G99_DISCHARGE_CAP = 3.68;

export const assembleHardwareDetails = (inverter, batteries) => {
  let batteryCapacity = 0;
  const batteryDegradationCost = inverter.unit_details.battery_degradation_cost;
  const batteryEfficiency = inverter.unit_details.battery_efficiency;
  // Initialize with Infinity to find the minimum value
  let batteryMaxChargePower = Infinity;
  let batteryMaxDischargePower = Infinity;

  const inverterDetails = inverter;
  const batteryDetails = [];

  // Calculate the battery details
  batteries.forEach(battery => {
    const { unit_details, quantity } = battery;
    batteryCapacity += unit_details.battery_capacity * quantity;

    if (unit_details.battery_max_charge_power < batteryMaxChargePower) {
      batteryMaxChargePower = unit_details.battery_max_charge_power;
    }

    if (unit_details.battery_max_discharge_power < batteryMaxDischargePower) {
      batteryMaxDischargePower = unit_details.battery_max_discharge_power;
    }

    batteryDetails.push(battery);
  });

  // Include inverter details in the calculations
  const { unit_details } = inverter;

  if (unit_details.battery_max_charge_power < batteryMaxChargePower) {
    batteryMaxChargePower = unit_details.battery_max_charge_power;
  }

  if (unit_details.battery_max_discharge_power < batteryMaxDischargePower) {
    batteryMaxDischargePower = unit_details.battery_max_discharge_power;
  }

  return {
    batteryCapacity,
    batteryDegradationCost,
    batteryEfficiency,
    batteryMaxChargePower,
    batteryMaxDischargePower,
    inverterDetails,
    batteryDetails,
  };
};
