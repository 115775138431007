import { G99_DISCHARGE_CAP } from './assembleHardwareDetails';

const getBatteryMaxDischargePower = (
  batteryMaxDischargePower,
  hasExportLimit = false
) => {
  if (hasExportLimit && batteryMaxDischargePower > G99_DISCHARGE_CAP) {
    return G99_DISCHARGE_CAP;
  }
  return batteryMaxDischargePower;
};

export default getBatteryMaxDischargePower;
