import { useContext, useEffect, useState } from 'react';
import { Field } from 'formik';
import { Button, Form } from 'react-bootstrap';
import cx from 'classnames';
import {
  StyledCard,
  StyledCardBody,
  StyledHeader,
  StyledIcon,
} from '../../../ui/Styled';
import BasicSpinner from '../../../ui/BasicSpinner';
import inverterSvg from '../../../assets/svg/inverter.svg';
import ErrorLabel from '../../../ui/ErrorLabel';
import { OnboardingContext } from '../../../context/OnboardingContext';
import { getUnitList } from '../../../utils/dataUtils';
import { getSupportedHardware, HardwareTypes } from '../../../api/Optimise';

const InverterDetails = () => {
  const {
    inverterManufacturer,
    setInverterManufacturer,
    inverterUnitList,
    setInverterUnitList,
    batteries,
  } = useContext(OnboardingContext);

  const [inverterConfigList, setInverterConfigList] = useState(null);

  const selectInverterManufacturer = manufacturer => {
    setInverterManufacturer(manufacturer);
    setInverterUnitList(
      getUnitList(inverterConfigList?.manufacturer_list, manufacturer)
    );
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      const supportedInverters = await getSupportedHardware(
        HardwareTypes.INVERTER
      );
      setInverterConfigList(supportedInverters);
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    if (inverterManufacturer) {
      selectInverterManufacturer(inverterManufacturer);
    }
  }, [inverterConfigList]);

  return (
    <div className="mb-2 pb-2">
      <StyledCard>
        <StyledHeader>
          <h4>Inverter Details</h4>
          <StyledIcon icon="inverter">
            <img src={inverterSvg} alt="Inverter" />
          </StyledIcon>
        </StyledHeader>
        <div>
          <StyledCardBody>
            <Field name="inverterManufacturer">
              {({ field, meta }) => (
                <Form.Group controlId="inverterManufacturer">
                  <Form.Label>
                    Select inverter manufacturer
                    {!inverterConfigList && <BasicSpinner />}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    size="md"
                    value={field.value}
                    onChange={e => {
                      selectInverterManufacturer(e.target.value);
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                    isValid={!meta.error && meta.touched}
                    placeholder="Select inverter manufacturer"
                    className={cx({ error: !!meta.error })}
                    disabled={!inverterConfigList}>
                    <optgroup>
                      <option value="" key="manufacturer-select">
                        Select inverter manufacturer
                      </option>
                      {inverterConfigList?.manufacturer_list?.map(
                        ({ manufacturer }, index) => (
                          <option
                            key={`manufacturer-${index}`}
                            value={manufacturer}>
                            {manufacturer}
                          </option>
                        )
                      )}
                    </optgroup>
                  </Form.Control>
                  {meta.error && meta.touched && (
                    <ErrorLabel label={meta.error} />
                  )}
                </Form.Group>
              )}
            </Field>
            <Form.Group className="not-found-text">
              <div>
                Manufacturer not shown?
                <Button
                  variant="link"
                  href="https://share.hsforms.com/1gsc8DNuRSkObygSHhibu0A2uro2"
                  target="_blank"
                  className="p-0 not-found-text ml-1">
                  Let us know
                </Button>
              </div>
            </Form.Group>
            <Field name="inverterUnitName">
              {({ field, meta }) => (
                <Form.Group controlId="inverterUnitName">
                  <Form.Label>Select inverter model</Form.Label>
                  <Form.Control
                    as="select"
                    size="md"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    isValid={!meta.error && meta.touched}
                    placeholder="Select inverter model"
                    disabled={!inverterUnitList?.length}
                    className={cx({ error: !!meta.error })}>
                    <optgroup>
                      <option value="" key="inverter-select">
                        Select inverter model
                      </option>
                      {inverterUnitList?.map(({ unit_name }, index) => (
                        <option key={`model-${index}`} value={unit_name}>
                          {unit_name}
                        </option>
                      ))}
                    </optgroup>
                  </Form.Control>
                  {meta.error && meta.touched && (
                    <ErrorLabel label={meta.error} />
                  )}
                </Form.Group>
              )}
            </Field>
          </StyledCardBody>
        </div>
      </StyledCard>
    </div>
  );
};

export default InverterDetails;
