import styled from 'styled-components';

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export default StyledHeader;
