import { createClient } from 'contentful';
import contentfulConfig from '../config/contentful';

const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
});

export const fetchContentfulTag = async (
  contentfulTag,
  contentType = 'General'
) => {
  try {
    const response = await client.getEntries({
      content_type: contentType,
      'fields.tag': contentfulTag,
    });
    if (response.items.length > 0) {
      return response.items[0].fields;
    } else {
      throw new Error('No data found');
    }
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
};
