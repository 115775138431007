export const getUnitList = (manufacturerList, manufacturer) => {
  let unitList = undefined;
  manufacturerList?.forEach(item => {
    if (manufacturer === item.manufacturer) {
      unitList = item.unit_list;
    }
  });
  return unitList;
};

export const getUnit = (unitList, unitName) => {
  let unit = undefined;

  const findUnit = list => {
    list?.forEach(item => {
      if (Array.isArray(item)) {
        findUnit(item); // Recurse into the array
      } else if (item.unit_name === unitName) {
        unit = item;
      }
    });
  };

  findUnit(unitList);
  return unit;
};
