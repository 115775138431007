import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import withContentfulTag from '../../hocs/withContentfulTag';
import { contentfulPropTypes } from '../../propTypes';
import { createUserTrace } from '../../api/Optimise';

const StyledReactMarkdown = styled(ReactMarkdown)`
  text-align: left;
  max-height: 50vh;
  overflow: auto;
  padding-right: 15px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ul {
    list-style: none;
    padding-left: 18px;

    li {
      margin-bottom: 8px;

      &::before {
        content: '\\2022';
        color: #ff0085;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 20px;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  top: -1px;
`;

const MpanWhatsThisModal = ({
  contentful,
  loading,
  fetched,
  showWhatsThis,
  onHideWhatsThis,
  onEdit,
}) => {
  useEffect(() => {
    const asyncCreateUserTrace = async () => {
      createUserTrace('MPAN Whats This Modal');
    };
    asyncCreateUserTrace();
  }, []);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showWhatsThis}
      onHide={onHideWhatsThis}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="pt-0">
        {!loading && fetched && (
          <Container fluid>
            <Row>
              <Col className="text-center">
                <h1 className="pb-4 text-left">{contentful?.title}</h1>
                <StyledReactMarkdown>{contentful?.body}</StyledReactMarkdown>
                <div className="text-left">
                  If the MPAN shown doesn’t look right you can{' '}
                  <StyledButton
                    variant="link"
                    className="p-0 m-0"
                    onClick={onEdit}>
                    change it
                  </StyledButton>
                  .
                </div>
                <div className="mb-3 text-right padding-0 margin-0">
                  <Button
                    className="px-5 d-inline-block"
                    variant="primary"
                    onClick={onHideWhatsThis}>
                    {contentful?.primaryButtonText}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};

MpanWhatsThisModal.propTypes = {
  ...contentfulPropTypes,
};

export default withContentfulTag(MpanWhatsThisModal, 'web_app_mpan_modal');
