import React from 'react';
import withContentfulTag from '../../hocs/withContentfulTag';
import { contentfulPropTypes } from '../../propTypes';
import errorDisc from '../../assets/svg/disc/error-disc.svg';

const SystemUnavailableContainer = ({ contentful }) => {
  return (
    <>
      <p>
        <img src={errorDisc} alt="Warning" />
      </p>
      <h1>{contentful?.title}</h1>
      <p>{contentful?.body}</p>
    </>
  );
};

SystemUnavailableContainer.propTypes = {
  ...contentfulPropTypes,
};

export default withContentfulTag(
  SystemUnavailableContainer,
  'web_app_service_unavailable_modal'
);
