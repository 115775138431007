import styled from 'styled-components';

const StyledCard = styled.div`
  border: 2px solid rgb(175, 182, 185) !important;
  margin-bottom: 1rem;
  box-shadow: none !important;
  border-radius: 4px !important;
`;

export default StyledCard;
