import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const StyledCardBody = styled(Card.Body)`
  padding: 1rem;
  background-color: white;
  border-top: 2px solid rgb(175, 182, 185) !important;
`;

export default StyledCardBody;
